<template>
  <div class="alipay"></div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "Alipay",
  data() {
    return {
      pay: "",
    };
  },
  mounted() {
    console.log(this.$route.query);
    api
      .web_pay({
        order_sn: this.$route.query.order_sn,
        pay_type: Number(this.$route.query.type),
      })
      .then((res) => {
        if (res.data.code == 1) {
          const div = document.createElement("div");
          div.id = "formdata";
          div.innerHTML = res.data.data;
          document.body.appendChild(div);
          const form = document.getElementById("formdata");
          document.forms[0].submit();
          document.body.removeChild(form);
        }else{
          this.$message.error(res.data.msg)
        }
      });
  },
};
</script>

<style>
</style>