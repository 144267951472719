<template>
  <div id="app" style="background: #f5f5f5">
    <Tabs></Tabs>
    <!-- <img class="banner" :src="this.url.imgUrl + bannerImg" alt="立优教育" /> -->

    <!-- 导航 -->
    <div id="nav_bar_box">
      <ul>
        <li>
          <div
            class="nav-item"
            :class="[isSelect == item.id ? 'nav_name_active' : '']"
            v-for="item in navBar"
            :key="item.id"
            @click="change(item)"
          >
            <!-- <div :class="{'nav_aaa':item.id!=index}" @click="tocolor(index)"> -->
            {{ item.name }}
            <!-- </div> -->
          </div>
        </li>
      </ul>
    </div>
    <!-- 内容区 版心 -->
    <div id="content_box">
      <div id="content" v-loading="culoumloading">
        <!-- 基础课程 -->
        <!-- 标题 -->
        <div class="title_box">
          <div class="title_top">
            <img src="../../assets/img/zhuangshi.png" alt="立优教育" />
            <div class="middle_txt">
              <span>{{ isSelect == 1291 ? "中级" : "基础" }}</span>
              <span>课程</span>
            </div>
            <img src="../../assets/img/zhuangshi2.png" alt="立优教育" />
          </div>
          <div class="title_down">搭建知识框架，梳理教材知识，理解记忆所学</div>
        </div>
        <!-- 中级课程内容 -->
        <div class="middle_course_box">
          <!-- v-if="btnStatus==false" -->
          <!-- <img :src="navBar[0].middle_img" alt="立优教育"> -->
          <culoum :nav="isSelect" :type="1" :goodid="ids[0]" />
          <!-- <img
            :src="this.url.imgUrl + classImg.middle_img"
            alt="立优教育"
          />-->
        </div>
        <!-- 中级课程 -->
        <!-- 标题 -->
        <div class="title_box" v-show="isSelect != 1291">
          <div class="title_top">
            <img src="../../assets/img/zhuangshi.png" alt="立优教育" />
            <div class="middle_txt">
              <span>中级</span>
              <span>课程</span>
            </div>
            <img src="../../assets/img/zhuangshi2.png" alt="立优教育" />
          </div>
          <div class="title_down">搭建知识框架，梳理教材知识，理解记忆所学</div>
        </div>
        <!-- 中级课程内容 -->
        <div class="middle_course_box" v-show="isSelect != 1291">
          <!-- v-if="btnStatus==false" -->
          <!-- <img :src="navBar[0].middle_img" alt="立优教育"> -->
          <culoum :nav="isSelect" :type="3" :goodid="ids[1]" />
          <culoum :nav="isSelect" :type="4" :goodid="ids[2]" />
          <!-- <img
            :src="this.url.imgUrl + classImg.middle_img"
            alt="立优教育"
          />-->
        </div>
        <!-- 高级 -->
        <div class="title_box">
          <div class="title_top">
            <img src="../../assets/img/zhuangshi.png" alt="立优教育" />
            <div class="middle_txt">
              <span>高级</span>
              <span>课程</span>
            </div>
            <img src="../../assets/img/zhuangshi2.png" alt="立优教育" />
          </div>
          <div class="title_down">搭建知识框架，梳理教材知识，理解记忆所学</div>
        </div>
        <!-- 高级课程内容 -->
        <div class="middle_course_box">
          <culoum
            :nav="isSelect"
            :type="2"
            :goodid="ids[isSelect == 1291 ? 1 : 3]"
          />
          <!-- <img
            :src="this.url.imgUrl + classImg.high_img"
            alt="立优教育"
            style="margin-bottom: 36px;"
          />-->
        </div>

        <!-- 版心结尾---------------------------------------- -->
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
import culoum from "@/components/culum";
export default {
  components: {
    culoum,
  },
  data() {
    return {
      culoumloading: false,
      bannerImg: "/assets/img/course.jpg", //banner
      hot_id: "", //首页热门课程传参
      isSelect: 1135, // 导航栏选中
      ids: [],
      navBar: [
        {
          id: 1135,
          name: "一级建造师",
          ids: [],
        },
        {
          id: 1145,
          name: "二级建造师",
          ids: [],
        },
        {
          id: 1165,
          name: "执业药师",
          ids: [],
        },
        {
          id: 1154,
          name: "中级注册安全工程师",
          ids: [],
        },
        {
          id: 1291,
          name: "监理工程师",
          ids: [],
        },
      ],
      btnStatus: false,
      classImg: "",
    };
  },
  methods: {
    // 获取地址栏参数
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // 导航栏事件
    change(el) {
      this.isSelect = el.id;
      this.ids = el.ids;
      console.log(this.ids);
      if (el != null) {
        this.classImg = el;
        this.btnStatus = true;
      }
      this.culoumloading = true;
      setTimeout(() => {
        this.culoumloading = false;
      }, 300);
    },
  },
  created() {
    api.course_goods_map().then((res) => {
      // console.log(res);
      this.ids = res.data.data.yijian;
      this.navBar[0].ids = res.data.data.yijian;
      this.navBar[1].ids = res.data.data.erjian;
      this.navBar[2].ids = res.data.data.yaoshi;
      this.navBar[3].ids = res.data.data.anquan;
      this.navBar[4].ids = res.data.data.jianli;
    });
    let isnav = this.$route.query.isSelect;
    // console.log(isnav)
    this.isSelect = isnav ? Number(isnav) : 1135;
    this.hot_id = this.GetQueryString("hot_id");
    // api.category_list().then((res) => {
    //   let a = [];
    //   res.data.data.list.forEach((e) => (a = [...a, ...e.children]));
    //   this.navBar = a;
    // });
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 400px;
  vertical-align: bottom;
}

/* 导航 */
// #app{
//   background: #000;
// }
.nav_box {
  width: 100%;
  height: 100px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.nav_box .nav_content {
  height: 100%;
  display: flex;
  align-items: center;
  /* margin-left: 410px; */
}

.nav_box #line {
  width: 2px;
  height: 20px;
  background: #e5e3e3;
  border-radius: 1px;
  margin: 0 30px;
}

.nav_box .nav {
  display: flex;
}

.nav_btn {
  height: 34px;
  background: #3e7eff;
  opacity: 0.1;
  border-radius: 17px;
}

.nav_box .nav .nav-item {
  width: auto;
  // height: 34px;
  // line-height: 34px;
  /* background: #3e7eff; */
  /* opacity: 0.1; */
  padding: 5px 20px;
  margin-right: 33px;
  font-weight: 500;
  cursor: pointer;
  font-family: PingFang SC;
  /* border-radius: 17px; */
}

.nav_aaa {
  background: #3e7eff;
  color: #fff;
  border-radius: 20px;
}

/* 内容区 */

#content_box {
  width: 100%;
  padding-bottom: 30px;
  /* height: 5000px; */
  /* margin: auto; */
  background: #f5f5f5;
}

/* 版心 */

#content {
  width: 1200px;
  /* height: 3000px; */
  margin: auto;
  overflow: hidden;
  // margin-bottom: 86px;
}

/* 标题 */

#content .title_box {
  width: 350px;
  height: 78px;
  margin: 80px auto 58px;
}

#content .title_box .title_top {
  display: flex;
  align-items: center;
  justify-content: center;
}

#content .title_box .title_top img {
  width: 26px;
  height: 19px;
}

.title_box .title_top .middle_txt {
  margin: 0 17px;
}

.title_box .title_top .middle_txt span:nth-child(1) {
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #a2b3ce;
  line-height: 30px;
  background: linear-gradient(0deg, #4a68f0 0%, #6992f9 99.31640625%);
  -webkit-background-clip: text;
  /* background-clip: content-box; */
  -webkit-text-fill-color: transparent;
}

.title_box .title_top .middle_txt span:nth-child(2) {
  width: 97px;
  height: 47px;
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #a2b3ce;
  line-height: 30px;
  background: linear-gradient(0deg, #2b2a32 0%, #35343f 98.779296875%);
  /* background-clip: content-box; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title_box .title_down {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  text-align: center;
  color: #555555;
  margin-top: 14px;
}

/* 中级课程 */

.middle_course_box {
  width: 100%;
  /* height: 1211px; */
  // background: #fff;
}

/* 导航 */

#nav_bar_box {
  width: 1200px;
  background: #fff;
  height: 70px;
  margin: 20px auto 0;
  border-radius: 6px;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}

#nav_bar_box ul {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#nav_bar_box ul li {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

#nav_bar_box ul li div {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  // margin-right: 65px;
  text-align: center;
  padding: 5px 20px;
  cursor: pointer;
}

.nav_name_active {
  color: #fff;
  padding: 7px 20px;
  border-radius: 6px;
  /* background: #3e7eff26; */
  background: #3e7eff;
  border-radius: 20px;
}
</style>
