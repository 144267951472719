<template>
  <div class="shopdetail" style="background: #f6f9fc">
    <Tabs></Tabs>

    <div class="content">
      <div class="detailcard">
        <div class="tit">首页 &nbsp; > &nbsp; {{ detail.title }}</div>
        <div class="cardbox">
          <div class="l">
            <el-carousel height="334px" arrow="always">
              <el-carousel-item v-for="item in detail.images" :key="item">
                <img :src="item" alt="" />
                <!-- <el-image :src="item" fit="cover"></el-image> -->
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="r">
            <h2>{{ detail.title }}</h2>
            <div class="price">
              <span> 价 格: </span>
              ¥{{
                istype == 1
                  ? goods_price1 == 0
                    ? `${detail.course_min_price} - ${detail.course_max_price}`
                    : goods_price1
                  : goods_price2 == 0
                  ? `${detail.course_min_price} - ${detail.course_max_price}`
                  : goods_price2
              }}
            </div>
            <div class="class">
              <div class="bt">班 级:</div>
              <div class="list">
                <span @click="istype = 2" :class="istype == 2 ? 'act' : ''">
                  全科
                </span>
                <span @click="istype = 1" :class="istype == 1 ? 'act' : ''">
                  单科
                </span>
              </div>
            </div>
            <div class="class" v-show="istype == 1">
              <div class="bt">科 目:</div>
              <div class="list">
                <span
                  v-for="item in detail.course_type2_ids"
                  :class="type1_id.includes(item.course_id) ? 'act' : ''"
                  :key="'type1_id' + item.course_id"
                  @click="priceClick(item)"
                >
                  {{ item.sku_name }}
                </span>
              </div>
            </div>
            <div class="class" v-show="istype == 2">
              <div class="bt">科 目:</div>
              <div class="list">
                <span
                  v-for="item in detail.course_type1_ids"
                  :class="type2_id == item.course_id ? 'act' : ''"
                  :key="'type2_id' + item.course_id"
                  @click="priceClick(item)"
                >
                  {{ item.sku_name }}
                </span>
              </div>
            </div>
            <div class="class">
              <div class="bt">服 务:</div>
              <div class="desc">{{ detail.serve_content }}</div>
            </div>
            <div class="class">
              <div class="bt" style="margin-top: 5px">资 料:</div>
              <div class="desc" style="margin-top: 0; padding-top: 5px">
                <span
                  style="border: 1px solid; margin-right: 12px"
                  v-for="item in detail.book_content.split('|')"
                  :key="item"
                >{{ item.trim() }}</span>
              </div>
            </div>
            <div class="shopbut" @click="goup">立即购买</div>
          </div>
        </div>
      </div>
      <div class="detail">
        <div class="detaill">
          <div class="tit">
            <span @click="detailtype = 1" :class="detailtype == 1 ? 'act' : ''"
              >班级介绍</span
            >
            <span @click="detailtype = 2" :class="detailtype == 2 ? 'act' : ''"
              >课程目录</span
            >
          </div>
          <iframe
            v-show="detailtype == 1"
            :src="detail.course_description"
            frameborder="0"
            ref="iframes"
            style="height: 100%; width: 910px"
          ></iframe>
          <div v-show="detailtype == 2" class="list">
            <el-collapse v-model="activeNames">
              <el-collapse-item
                v-for="item in detail.chapter_list"
                :key="'fu' + item.chapter_category_id"
                class="list1"
                :name="item.chapter_category_id"
              >
                <template slot="title">
                  <div class="l1tit">
                    {{ item.chapter_category_name }}
                    <!-- <img src="../../assets/img/shouqi@2x.png" alt="" /> -->
                  </div>
                </template>
                <div
                  v-for="child in item.list"
                  :key="'child' + child.chapter_id"
                  class="list2"
                >
                  <div class="l1tit">
                    <span>
                      <img src="../../assets/img/kecheng@2x.png" alt="" />
                      {{ child.name }}
                    </span>
                    <span>含{{ child.video_count }}个课程 </span>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="detailr">
          <div class="tit">授课老师</div>
          <div class="desc">
            <div
              v-for="item in detail.faculty_list"
              class="teacher"
              :key="'teacher' + item.id"
            >
              <div class="t">
                <div class="l">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.m_image"
                    alt=""
                  />
                </div>
                <div class="r">
                  <h2>{{ item.name }}</h2>
                  <p>{{ item.brief_desc }}</p>
                </div>
              </div>
              <div class="b">
                {{ item.detail_desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "Shopdetail",
  data() {
    return {
      activeNames: [],
      detailtype: 1,
      istype: 2,
      type1_id: [],
      type2_id: "",
      goods_price2: 0,
      goods_price1: 0,
      detail: {
        goods_id: 1,
        title: "",
        course_type1_ids: [],
        course_type2_ids: [],
        images: [],
        serve_content: "",
        book_content: "",
        course_description: "",
        course_min_price: 0,
        course_max_price: 0,
        chapter_list: [],
        faculty_list: [],
      },
    };
  },
  created() {
    api
      .pc_detail({
        course_category_id: this.$route.params.course_category_id,
        goods_id: this.$route.params.class_type_id,
      })
      .then((res) => {
        if (res.data.code == 1) {
          this.detail = res.data.data;
          this.goods_list = this.detail.course_type2_ids;
          this.priceClick(res.data.data.course_type1_ids[0]);
        } else {
          this.$message.error(res.data.msg);
        }
      });
  },
  mounted() {
    let that = this;
    window.addEventListener(
      "message",
      function (event) {
        // 把子窗口发送过来的数据显示在父窗口中
        console.log(event.data);
        that.$refs.iframes.style.height = event.data + 300 + "px";
        // document.getElementById("content").innerHTML = event.data;
      },
      false
    );
  },
  watch: {
    type1_id(val) {
      let c = 0;
      val.forEach((e) => {
        this.detail.course_type2_ids.forEach((a) => {
          if (a.course_id == e) {
            c = c + Number(a.price);
          }
        });
      });
      this.goods_price1 = c;
    },
  },
  methods: {
    goup() {
      if (this.istype == 1) {
        if (this.type1_id.length == 0) {
          this.$message.error("至少选择一个课程哦~");
        } else {
          let Detail = {
            ids: this.type1_id,
            goodID: this.detail.goods_id,
            type: 2,
          };
          this.$store.commit("ShopDetail", Detail);
          this.$router.push({ path: "/order/order_step1" });
        }
      } else {
        if (this.type2_id == "") {
          this.$message.error("至少选择一个课程哦~");
        } else {
          let Detail = {
            ids: this.type2_id,
            goodID: this.detail.goods_id,
            type: 1,
          };
          this.$store.commit("ShopDetail", Detail);
          this.$router.push({ path: "/order/order_step1" });
        }
      }
    },
    priceClick(item) {
      if (this.istype == 2) {
        this.type2_id = item.course_id;
        this.goods_price2 = item.price;
      } else {
        let a = this.type1_id.indexOf(item.course_id);
        if (a > -1) {
          this.type1_id.splice(a, 1);
        } else {
          this.type1_id.push(item.course_id);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__arrow {
  display: none;
}
::v-deep .el-collapse-item__header.is-active {
  background: #f5f6f6;
  position: relative;
}
::v-deep .el-icon-arrow-right:before {
  content: "";
  display: block;
  width: 12px;
  height: 8px;
  transform: rotate(180deg);
  background: url("../../assets/img/shouqi@2x.png") no-repeat center;
  background-size: 100%;
}
::v-deep .el-collapse-item__arrow.is-active {
  transform: rotate(180deg);
}
::v-deep .el-collapse-item__header.is-active::before {
  content: "";
  display: block;
  width: 4px;
  height: 14px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #007fff;
}
::v-eddp .el-image {
  height: 100%;
}
::v-deep .el-collapse {
  border: none;
}
::v-deep .el-collapse-item__header {
  border: none;
  height: 68px;
  line-height: 68px;
  padding-right: 40px;
  position: relative;
}
::v-deep .el-collapse-item__header::after {
  content: "";
  display: block;
  width: 820px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #f5f5f5;
}
.content {
  width: 1200px;
  margin: 24px auto;
  min-height: 90vh;
  .detailr {
    width: 280px;
    .tit {
      padding: 26px 19px;
      background: #fff;
      margin-bottom: 1px;
      font-size: 18px;
      font-weight: 400;
      color: #666666;
    }
    .desc {
      background: #fff;
      padding: 0 19px;
      //   height: 609px;
      line-height: 1;
      .teacher:last-child {
        border: none;
      }
      .teacher {
        padding: 20px 0;
        border-bottom: 1px dashed #efefef;

        .t {
          display: flex;
          align-items: center;
          .l {
            width: 84px;
            height: 58px;
            margin-right: 11px;
          }
          .r {
            h2 {
              font-size: 18px;
              font-weight: 400;
              color: #000031;
              margin-bottom: 7px;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        .b {
          font-size: 14px;
          margin-top: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
      }
    }
  }
  .detail {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    // margin-bottom: 234px;
    .detaill {
      // background: #fff;
      border-radius: 4px;
      width: 900px;
      overflow: hidden;
      .list {
        background: #fff;
        min-height: 900px;
        // overflow-y: scroll;
        .list1 {
          overflow: hidden;
          .list2 .l1tit::after {
            width: 780px;
          }
          .act {
            background: #f5f6f6;
            position: relative;
            img {
              transform: rotate(0deg) !important;
            }
          }
          .act::before {
            content: "";
            display: block;
            width: 4px;
            height: 14px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: #007fff;
          }
          .l1tit {
            padding: 15px 40px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
            color: #00032c;
            img {
              width: 12px;
              height: 8px;
              transform: rotate(180deg);
              transition: all 0.3s;
            }
            span:first-child {
              display: flex;
              margin-left: 22px;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              color: #333845;
              img {
                width: 14px;
                height: 18px;
                margin-right: 10px;
              }
            }
            span:last-child {
              color: #999999;
              font-weight: 400;
              font-size: 14px;
            }
          }
          .l1tit::after {
            content: "";
            display: block;
            width: 820px;
            height: 1px;
            background: #f5f5f5;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .tit {
        display: flex;
        padding: 30px 40px;
        background: #fff;
        span {
          font-size: 18px;
          font-weight: 400;
          color: #666666;
          margin-right: 80px;
          cursor: pointer;
        }
        .act {
          position: relative;
          color: #007fff;
        }
        .act::after {
          content: "";
          display: block;
          width: 20px;
          height: 4px;
          background: #007fff;
          border-radius: 4px 4px 0 0;
          position: absolute;
          bottom: -11px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .detailcard {
    padding: 30px;
    background: #fff;
    .tit {
      font-size: 14px;
      font-weight: 400;
      color: #6b6e78;
    }
    .cardbox {
      display: flex;
      margin-top: 20px;
      .l {
        width: 560px;
        height: 334px;
        margin-right: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .r {
        line-height: 1;
        max-width: 550px;
        h2 {
          font-size: 26px;
          font-weight: 500;
          color: #00032c;
          margin-top: 10px;
        }
        .price {
          width: 510px;
          margin-top: 21px;
          background: #f6f9fc;
          padding: 26px 20px;
          box-sizing: border-box;
          font-size: 24px;
          font-weight: 500;
          color: #fb321e;
          span {
            font-size: 14px;
            color: #6b6e78;
            font-weight: 400;
          }
        }
        .shopbut {
          background: linear-gradient(90deg, #ff897e 0%, #fe230c 100%);
          border-radius: 4px;
          padding: 14px;
          text-align: center;
          box-sizing: border-box;
          width: 200px;
          cursor: pointer;
          font-size: 22px;
          margin-top: 26px;
          font-weight: 500;
          color: #ffffff;
        }
        .class {
          display: flex;
          .desc {
            padding: 11px 0;
            margin-left: 17px;
            margin-top: 10px;
            font-size: 14px;
            color: #ff9024;
            font-weight: 400;
            span{
              padding: 0 5px;
            }
          }
          .list {
            display: flex;
            flex-wrap: wrap;
            span {
              padding: 10px 12px;
              border-radius: 4px;
              background: #f4f4f4;
              box-sizing: border-box;
              font-size: 16px;
              font-weight: 400;
              margin-top: 10px;
              border: 1px solid #f4f4f4;
              cursor: pointer;
              margin-left: 17px;
              color: #303030;
            }
            .act::after {
              content: "";
              display: block;
              width: 22px;
              height: 18px;
              position: absolute;
              bottom: 0;
              right: 0;
              background: url("../../assets/img/XUANZHONG@2x.png") no-repeat
                center;
              background-size: 100% 100%;
            }
            .act {
              position: relative;
              border: 1px solid #fe230c;
              color: #fe230c;
              background: #fff;
            }
          }

          .bt {
            min-width: 37px;
            margin-left: 25px;
            font-size: 14px;
            margin-top: 21px;
            font-weight: 400;
            color: #6b6e78;
          }
        }
      }
    }
  }
}
/*滚动条整体部分,必须要设置*/
::-webkit-scrollbar {
  width: 10px;
  background: #efefef;
  border-radius: 5px;
}
/*滚动条的轨道*/
::-webkit-scrollbar-track {
  background-color: #efefef;
  border-radius: 5px;
}
/*滚动条的滑块按钮*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 10px;
  border-radius: 5px;
  height: 120px;
  background: #c9c9cc;
}
</style>