<template>
  <div class="culum">
    <div class="box">
      <div class="list">
        <div
          :class="
            type == 1
              ? 'l l1'
              : type == 2
              ? 'l l2'
              : type == 3
              ? 'l l3'
              : 'l l4'
          "
        >
          <div class="tit">
            &nbsp&nbsp{{ type == 1 || type == 3 ? "新班上线" : "限制名额" }}
          </div>
          <h2
            :style="
              nav == 1154 || nav == 1165 || nav == 1291
                ? 'margin-top:18px;'
                : ''
            "
          >
            {{ nav == 1165 ? "2022" : (nav == 1291 ? '' : '2023') }}
          </h2>
          <h2 style="margin-top: -5px">{{ nav | navs | types(type) }}</h2>
          <div
            :style="nav == 1154 || nav == 1165 ? 'padding-bottom:30px;' : ''"
            class="detail"
            v-html="ltit"
          ></div>
          <span @click="bm">查看课程列表</span>
          <span @click="ck">在线咨询</span>
        </div>
        <div
          :class="
            type == 1
              ? 'r r1'
              : type == 2
              ? 'r r2'
              : type == 3
              ? 'r r3'
              : 'r r4'
          "
        >
          <div class="t">
            <div class="nav">
              <div>
                <img
                  :src="imgUrl + '/assets/img/img' + type + '/left123456.png'"
                  alt
                />
                精品课程
                <img
                  :src="imgUrl + '/assets/img/img' + type + '/right213546.png'"
                  alt
                />
              </div>
              <div>
                <img
                  :src="imgUrl + '/assets/img/img' + type + '/left123456.png'"
                  alt
                />
                优质资料
                <img
                  :src="imgUrl + '/assets/img/img' + type + '/right213546.png'"
                  alt
                />
              </div>
              <div>
                <img
                  :src="imgUrl + '/assets/img/img' + type + '/left123456.png'"
                  alt
                />
                赠送服务
                <img
                  :src="imgUrl + '/assets/img/img' + type + '/right213546.png'"
                  alt
                />
              </div>
              <div>
                <img
                  :src="imgUrl + '/assets/img/img' + type + '/left123456.png'"
                  alt
                />
                课程备注
                <img
                  :src="imgUrl + '/assets/img/img' + type + '/right213546.png'"
                  alt
                />
              </div>
            </div>
            <div class="navlist">
              <ul>
                <li>
                  <div
                    class="firstnavlist"
                    v-for="(item, i) in list1"
                    :key="'list1' + i"
                  >
                    <div class="top">
                      <img
                        :src="
                          imgUrl + '/assets/img/img' + type + '/no12345.png'
                        "
                        alt
                      />
                      <span>{{ item.tittle }}</span>
                    </div>
                    <span v-if="item.desc && item.desc != ''" class="desc">{{
                      item.desc
                    }}</span>
                  </div>
                </li>
                <li :class="nav == 1165 ? 'pb12' : ''">
                  <div
                    class="firstnavlist"
                    v-for="(item, i) in list2"
                    :key="'list2' + i"
                  >
                    <div class="top">
                      <img
                        :src="
                          imgUrl + '/assets/img/img' + type + '/no12345.png'
                        "
                        alt
                      />
                      <span>{{ item.tittle }}</span>
                    </div>
                    <span v-if="item.desc && item.desc != ''" class="desc">{{
                      item.desc
                    }}</span>
                  </div>
                </li>
                <li :class="list3.length > 12 ? 'pb12' : ''">
                  <p v-for="(item, i) in list3" :key="'list3' + i">
                    <img
                      :src="imgUrl + '/assets/img/img' + type + item.img"
                      alt
                    />
                    <span>{{ item.tit }}</span>
                  </p>
                </li>
                <li style="margin: 0; padding-top: 0">
                  <div v-for="(item, i) in list4" :key="'list4' + i">
                    <div class="p">
                      <div class="o">
                        <span>0{{ i + 1 }}</span>
                      </div>
                      <div class="h">{{ item.tit }}</div>
                    </div>
                    <div
                      class="p"
                      v-for="(detail, i) in item.list"
                      :key="'detail' + i"
                    >
                      {{ detail }}
                    </div>
                  </div>

                  <!-- <div class="p" style="margin: 0">
                    <div class="o"><span>02</span></div>
                    <div class="h">基础精讲课</div>
                  </div>
                  <div class="p" style="margin-bottom: 0"></div>
                  <div class="p">
                    2.
                    题点结合强化记忆，高效学习，帮助考生循序渐进掌握知识点及基本考点，全面夯实基础
                  </div>
                  <div class="p" style="margin: 0">
                    <div class="o"><span>03</span></div>
                    <div class="h">习题解析课</div>
                  </div>
                  <div class="p" style="margin-bottom: 0">
                    1. 精编习题，深度剖析考题考点出题规律，命中规律；
                  </div>
                  <div class="p">2. 了解考试题型，掌握命题规律，针对性学习</div>-->
                </li>
              </ul>
            </div>
          </div>
          <div class="b">
            <div class="price">
              <h2>{{ nav == 1291 ? "理论和法规\n合同管理" : (typeof price[0].name != 'undefined' ? price[0].name : "公共单科") }}:</h2>
              <!-- <div class="old" v-show="nav != 1165">
                <p>原价</p>
                <p>¥{{ price[0].old }}</p>
              </div>-->
              <div class="but" @click="bm">
                <span class="price_">
                  <span class="blod">¥</span>{{ price[0].new }}
                </span>
                抢购
              </div>
            </div>
            <div class="price" v-show="nav != 1165">
              <h2>{{ nav == 1291 ? "案例分析\n目标管理" : (typeof price[1].name != 'undefined' ? price[1].name : "实务单科") }}:</h2>
              <!-- <div class="old">
                <p>原价</p>
                <p>¥{{ price[1].old }}</p>
              </div>-->
              <div class="but" @click="bm">
                <span class="price_">
                  <span class="blod">¥</span>{{ price[1].new }}
                </span>
                抢购
              </div>
            </div>
            <div class="price">
              <h2>{{ typeof price[2].name != 'undefined' ? price[2].name : "全科" }}:</h2>
              <!-- <div class="old" v-show="nav != 1165">
                <p>原价</p>
                <p>¥{{ price[2].old }}</p>
              </div>-->
              <div class="but" @click="bm">
                <span class="price_">
                  <span class="blod">¥</span>{{ price[2].new }}
                </span>
                抢购
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      class="bmdialog"
      title="提示"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <div class="detail">
        <p>请留下您的报考科目和联系方式</p>
        <p>班主任老师将尽快与您联系～</p>
      </div>
      <el-form ref="form" :model="bmform" label-width="100px">
        <el-form-item label="报考专业：">
          <el-select v-model="bmform.region" placeholder="请选择专业">
            <el-option
              v-for="item in subjectList"
              :key="item.subject_id"
              :label="item.name"
              :value="item.subject_id"
            ></el-option>
            <!-- <el-option label="区域二" value="beijing"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input
            maxlength="11"
            v-model="bmform.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="bmsubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  filters: {
    navs(e) {
      if (e == 1165) {  // 药师
        return ["尊享畅学班", "高效点题班", "钻石密训班", "无忧通关班"];
      } else if (e == 1291) { // 监理
        return ["精讲突击班", "", "", "通关无忧班"];
      } else if (e == 1154) { // 安全
        return ["精讲启航班", "VIP无忧班", "领航直通班", "通关无忧班"];
      } else if (e == 1145){  // 二建
        return ["精讲启航班", "畅学保障班", "纯金特训班", "通关无忧班"];
      } else {  // 一建
        return ["精讲启航班", "VIP无忧班", "领航直通班", "通关无忧班"];
      }
    },
    types(val, e) {
      switch (e) {
        case 1:
          return val[0];
          break;
        case 2:
          return val[3];
          break;
        case 3:
          return val[1];
          break;
        case 4:
          return val[2];
          break;
      }
    },
  },
  props: {
    nav: {
      type: Number,
      required: true,
      default: 1,
    },
    type: {
      type: Number,
      required: true,
      default: 1,
    },
    goodid: "",
  },
  name: "culum",
  data() {
    return {
      subjectList: [], // 科目列表
      dialogVisible: false,
      bmform: {
        mobile: "",
        region: "",
      },
      imgUrl: "",
      ltit: "",
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      price: [],
    };
  },
  watch: {
    nav(val) {
      this.navClick();
    },
  },
  created() {
    this.imgUrl = this.url.imgUrl;
    api.subject().then((res) => {
      this.subjectList = res.data.data;
    });
    this.navClick();
  },
  methods: {
    bmsubmit() {
      let mobile = this.bmform.mobile; //用户手机号
      let ts = Date.parse(new Date()); //当前时间戳
      let cid = 2; //渠道号
      let subject_id = this.bmform.region; //科目id
      let seed = cid + "-" + mobile + "-" + subject_id + "-" + ts;
      let sign = this.$md5(
        this.$md5(seed).toString().toLowerCase() + "landing_page"
      )
        .toString()
        .toLowerCase(); // 签名计算结果
      const reg = /^1[3-9][0-9]{9}$/; // 正则校验手机号
      if (!this.bmform.region) {
        this.$message.error({
          duration: 1500,
          message: "请选择专业!",
        });
        return false;
      } else if (
        this.bmform.mobile == "" ||
        this.bmform.mobile <= 10 ||
        !reg.test(this.bmform.mobile)
      ) {
        this.$message.error({
          duration: 1500,
          message: "请输入正确手机号!",
        });
        return false;
      } else if (this.$cookies.get("mobile") == mobile) {
        this.$message.error({
          duration: 2500,
          message: "一个手机号只允许提交一次,您已提交过,请勿重复提交!",
        });
        return false;
      } else {
        var data = {
          mobile: mobile,
          ts: ts,
          cid: cid,
          subject_id: subject_id,
          sign: sign,
        };
        api.landing_site(data).then((res) => {
          if (parseInt(res.data.code) == 1) {
            this.$message.success({
              duration: 1500,
              // message: "提交成功,一个手机号只允许提交一次,请勿重复提交!",
              message: "提交成功!",
            });
            this.bmform = {};
            this.$cookies.set("mobile", mobile, 30);
            this.dialogVisible = false;
          } else {
            this.$message.error({
              duration: 1500,
              message: "提交失败,请重新提交!",
            });
            this.dialogVisible = false;
          }
        });
      }
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    bm() {
      // console.log(this.goodid)
      this.$router.push({
        path: `/shopdetail/${this.nav}/${this.goodid}`,
      });
      
    },
    ck() {
      console.log(this.price);
      this.dialogVisible = true
      this.$emit('bmclick', {
      })
      // this.$router.push({
      //   name: "Course",
      //   params: { is_pageid: 2 },
      // });
    },
    navClick() {
      switch (this.nav) {
        case 1135: //1342 顺序  一建造
          switch (this.type) {
            case 1: // 启航
              this.price = [
                {
                  old: 0,
                  new: 698,
                  id: 25,
                },
                {
                  old: 0,
                  new: 798,
                },
                {
                  old: 0,
                  new: 1598,
                },
              ];
              this.list1 = [
                {
                  tittle: "超前预习课",
                  desc: "含：公共课+建筑\n市政、机电",
                },
                {
                  tittle: "精讲导学课",
                },
                {
                  tittle: "教材精讲课",
                },
                {
                  tittle: "必学专题课",
                  desc: "（公开课）",
                },
                {
                  tittle: "共性案例课",
                  desc: "（公开课）",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "历年在线真题",
                },
                {
                  tittle: "习题解析讲义",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "报考协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "超前预习课",
                  list: [
                    "新课程提前预习，专业老师进行提前布局，按照历年考情专项辅导",
                  ],
                },
                {
                  tit: "备考导学课",
                  list: [
                    "考情分析、考试介绍、考点分布、学习方法及学习计划安排，达到入门、熟悉学习方法的目标",
                  ],
                },
                {
                  tit: "基础精讲课",
                  list: [
                    "1. 根据近三年考试分值分布情况，梳理考试必学目标内容；",
                    "2. 题点结合强化记忆，高效学习，帮助考生循序渐进掌握知识点及基本考点，全面夯实基础",
                  ],
                },
              ];
              this.ltit = `
           <p>项目管理、工程经济、</p>
            <p>法规、建筑、机电、</p>
            <p>市政公用、</p>
            <p>公路、水利水电</p>`;
              break;
            case 2: // 通关
              this.price = [
                {
                  old: 0,
                  new: 9800,
                  id: 28,
                },
                {
                  old: 0,
                  new: 12800,
                },
                {
                  old: 0,
                  new: 25800,
                },
              ];
              this.ltit = `
           <p>项目管理、工程经济、</p>
            <p>法规、建筑、机电、</p>
            <p>市政公用、</p>
            <p>公路、水利水电</p>`;
              this.list1 = [
                {
                  tittle: "超前预习课",
                  desc: "含：公共课+建筑\n市政、机电",
                },
                {
                  tittle: "精讲导学课",
                },
                {
                  tittle: "教材精讲课",
                },
                {
                  tittle: "冲刺专题课",
                },
                {
                  tittle: "共性案例课",
                },
                {
                  tittle: "案例专项提分课",
                  desc: "（实务）",
                },
                {
                  tittle: "纯金考点课",
                },
                {
                  tittle: "考前突破密训",
                },
                {
                  tittle: "真题解析课",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "历年在线真题",
                },
                {
                  tittle: "习题解析讲义",
                },
                {
                  tittle: "冲刺专题讲义",
                },
                {
                  tittle: "每科2套考前预测卷",
                },
                {
                  tittle: "案例特训秘籍",
                },
                {
                  tittle: "强化宝典",
                },
                {
                  tittle: "考前密训资料",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "专属学习档案建立",
                  img: "/lixian1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },

                {
                  tit: "报考协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "重修服务",
                  img: "/chongxiu1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "案例特训课",
                  list: [
                    "1.根据3-5年考试规律及评卷指标，精确锁定案例分析题关键得分词句；",
                    "2.案例题专项训练，快速提升案例分析题解题、得分能力；",
                  ],
                },
                {
                  tit: "考前突破密训",
                  list: [
                    "独家考前密训，进一步给考生强化考试中的重中之重，增强考试中的信心",
                  ],
                },
              ];
              break;
            case 3: // VIP无忧
              this.price = [
                {
                  old: 0,
                  new: 1280,
                  id: 26,
                },
                {
                  old: 0,
                  new: 1580,
                },
                {
                  old: 0,
                  new: 3980,
                },
              ];
              this.ltit = `
           <p>项目管理、工程经济、</p>
            <p>法规、建筑、机电、</p>
            <p>市政公用、</p>
            <p>公路、水利水电</p>`;
              this.list1 = [
                {
                  tittle: "精讲导学课",
                  desc: "",
                },
                {
                  tittle: "教材精讲课",
                },
                {
                  tittle: "冲刺串讲课",
                },
                {
                  tittle: "共性案例课",
                },
                {
                  tittle: "案例专项提分课（实务）",
                },
                {
                  tittle: "真题解析课",
                  desc: "",
                },
                {
                  tittle: "教学督导课",
                  desc: "",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "思维导图",
                },
                {
                  tittle: "历年在线真题",
                },
                {
                  tittle: "习题解析讲义",
                },
                {
                  tittle: "冲刺专题讲义",
                },
                {
                  tittle: "考前两套预测卷",
                },
                {
                  tittle: "考前密训资料",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1对1服务",
                  img: "/1v1.png",
                },

                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "签署协议",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "两年不过退费",
                  img: "/chongxiu1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "冲刺串讲课",
                  list: [
                    "1. 以考点形式理清科目内各种真题类型，以及解答思路；",
                    "2. 归纳总结记忆技巧，精简核心考试信息；",
                    "3. 设置实用性更强实战专题，攻克考试盲区，针对性练习；",
                    "4. 快速记忆，攻克特殊提醒阶梯思路，增强实战能力",
                  ],
                },
                {
                  tit: "真题解析课",
                  list: [
                    "1. 针对近年考试真题情况，具体分析考试题目，考试考点和考察方法；",
                    "2. 带领学员了解具体考试难度，有针对性的复习；",
                    "3. 熟悉考试真题解题思路和方法",
                  ],
                },
              ];
              break;
            case 4: // 领航直通班
              this.price = [
                {
                  old: 0,
                  new: 1980,
                  id: 27,
                },
                {
                  old: 0,
                  new: 2580,
                },
                {
                  old: 0,
                  new: 6980,
                },
              ];
              this.ltit = `
           <p>项目管理、工程经济、</p>
            <p>法规、建筑、机电、</p>
            <p>市政公用、</p>
            <p>公路、水利水电</p>`;
              this.list1 = [
                {
                  tittle: "冲刺串讲课",
                  desc: "",
                },
                {
                  tittle: "共性案例课",
                },
                {
                  tittle: "案例专项提分课（实务）",
                },
                {
                  tittle: "纯金直播课",
                },
                {
                  tittle: "考前突破密训课",
                },
                {
                  tittle: "真题解析课",
                  desc: "",
                },
                {
                  tittle: "教学督导课",
                },
              ];
              this.list2 = [
                {
                  tittle: "思维导图",
                },
                {
                  tittle: "历年在线真题",
                },
                {
                  tittle: "习题解析讲义",
                },
                {
                  tittle: "冲刺专题讲义",
                },
                {
                  tittle: "考前两套预测卷",
                  desc: "（直播会讲）"
                },
                {
                  tittle: "通关72绝技",
                },
                {
                  tittle: "强化宝典",
                },
                {
                  tittle: "考前密训资料",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1对1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "专属学习档案建立",
                  img: "/lixian1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "签署协议",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "两年不过退费",
                  img: "/chongxiu1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "考前突破密训课",
                  list: [
                    "1. 临考前的最后冲刺课，针对性的系统剖析；",
                    "2. 精简知识点，进行最后一遍复习，用最少的时间有效提升知识点的掌握；",
                  ],
                },
                {
                  tit: "真题解析课",
                  list: [
                    "1. 针对近年考试真题情况，具体分析考试题目，考试考点和考察方法；",
                    "2. 带领学员了解具体考试难度，有针对性的复习；",
                    "3. 熟悉考试真题解题思路和方法",
                  ],
                },
              ];
              break;
          }
          break;
        case 1145: //1342 顺序    二级建造
          switch (this.type) {
            case 1:
              this.price = [
                {
                  old: 380,
                  new: 488,
                  id: 21,
                },
                {
                  old: 480,
                  new: 588,
                },
                {
                  old: 1480,
                  new: 1298,
                },
              ];
              this.list1 = [
                {
                  tittle: "超前预习课",
                  desc: "含：公共课+建筑\n市政、机电",
                },
                {
                  tittle: "精讲导学课",
                },
                {
                  tittle: "教材精讲课",
                },
                {
                  tittle: "必学专题课",
                  desc: "（公开课）",
                },
                {
                  tittle: "共性案例课",
                  desc: "（公开课）",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "历年在线真题",
                },
                {
                  tittle: "习题解析讲义",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "报考协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "超前预习课",
                  list: [
                    "新课程提前预习，专业老师进行提前布局，按照历年考情专项辅导",
                  ],
                },
                {
                  tit: "备考导学课",
                  list: [
                    "考情分析、考试介绍、考点分布、学习方法及学习计划安排，达到入门、熟悉学习方法的目标 ",
                  ],
                },
                {
                  tit: "基础精讲课",
                  list: [
                    "1. 根据近三年考试分值分布情况，梳理考试必学目标内容；",
                    "2. 题点结合强化记忆，高效学习，帮助考生循序渐进掌握知识点及基本考点，全面夯实基础",
                  ],
                },
                // {
                //   tit: "习题解析课",
                //   list: [
                //     "1. 精编习题，深度剖析考题考点出题规律，命中规律；",
                //     "2. 了解考试题型，掌握命题规律，针对性学习",
                //   ],
                // },
              ];
              this.ltit = `
           <p>施工管理、法规、建筑、</p>
            <p>机电、市政公用、公路、</p>
            <p>水利水电</p>`;
              break;
            case 2:
              this.price = [
                {
                  old: 0,
                  new: 5800,
                  id: 24,
                },
                {
                  old: 0,
                  new: 7800,
                },
                {
                  old: 0,
                  new: 15800,
                },
              ];
              this.ltit = `
          <p>施工管理、法规、建筑、</p>
            <p>机电、市政公用、公路、</p>
            <p>水利水电</p>`;
              this.list1 = [
                {
                  tittle: "超前预习课",
                  desc: "含：公共课+建筑\n市政、机电",
                },
                {
                  tittle: "精讲导学课",
                },
                {
                  tittle: "教材精讲课",
                },
                {
                  tittle: "冲刺专题课",
                },
                {
                  tittle: "共性案例课",
                },
                {
                  tittle: "案例专项提分课",
                  desc: "（实务）",
                },
                {
                  tittle: "纯金考点课",
                },
                {
                  tittle: "考前突破密训",
                },
                {
                  tittle: "真题解析课",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "历年在线真题",
                },
                {
                  tittle: "习题解析讲义",
                },
                {
                  tittle: "冲刺专题讲义",
                },
                {
                  tittle: "每科2套考前预测卷",
                },
                {
                  tittle: "案例特训秘籍",
                },
                {
                  tittle: "强化宝典",
                },
                {
                  tittle: "考前密训资料",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "专属学习档案建立",
                  img: "/lixian1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "报考协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "重修服务",
                  img: "/chongxiu1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "案例特训课",
                  list: [
                    "1.根据3-5年考试规律及评卷指标，精确锁定案例分析题关键得分词句；",
                    "2.案例题专项训练，快速提升案例分析题解题、得分能力；",
                  ],
                },
                {
                  tit: "考前突破密训",
                  list: [
                    "独家考前密训，进一步给考试强化考生中的重中之重，增强考试中的信心",
                  ],
                },
              ];
              break;
            case 3:
              this.price = [
                {
                  old: 0,
                  new: 698,
                  id: 22,
                },
                {
                  old: 0,
                  new: 898,
                },
                {
                  old: 0,
                  new: 1998,
                },
              ];
              this.ltit = `
           <p>施工管理、法规、建筑、</p>
            <p>机电、市政公用、公路、</p>
            <p>水利水电</p>`;
              this.list1 = [
                {
                  tittle: "超前预习课",
                  desc: "含：公共课+建筑\n市政、机电",
                },
                {
                  tittle: "精讲导学课",
                },
                {
                  tittle: "教材精讲课",
                },
                {
                  tittle: "冲刺专题课",
                },
                {
                  tittle: "共性案例课",
                },
                {
                  tittle: "必学专题课",
                  desc: "（公开课）",
                },
                {
                  tittle: "共性案例课",
                  desc: "（公开课）",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "历年在线真题",
                },
                {
                  tittle: "习题解析讲义",
                },
                {
                  tittle: "冲刺专题讲义",
                },
                {
                  tittle: "每科2套考前测试卷",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "报考协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "免费重修一年",
                  img: "/chongxiu1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "冲刺专题课",
                  list: [
                    "1. 以考点形式理清科目内各种真题类型，以及解答思路；",
                    "2. 归纳总结记忆技巧，精简核心考试信息；",
                    "3. 设置实用性更强实战专题，攻克考试盲区，针对性练习；",
                    "4. 快速记忆，攻克特殊提醒阶梯思路，增强实战能力",
                  ],
                },
                {
                  tit: "真题解析课",
                  list: [
                    "1. 针对近年考试真题情况，具体分析考试题目，考试考点和考察方法；",
                    "2. 带领学员了解具体考试难度，有针对性的复习；",
                    "3. 熟悉考试真题解题思路和方法",
                  ],
                },
              ];
              break;
            case 4:
              this.price = [
                {
                  old: 0,
                  new: 2280,
                  id: 23,
                },
                {
                  old: 0,
                  new: 2980,
                },
                {
                  old: 0,
                  new: 6980,
                },
              ];
              this.ltit = `
          <p>施工管理、法规、建筑、</p>
            <p>机电、市政公用、公路、</p>
            <p>水利水电</p>`;
              this.list1 = [
                {
                  tittle: "超前预习课",
                  desc: "含：公共课+建筑\n市政、机电",
                },
                {
                  tittle: "精讲导学课",
                },
                {
                  tittle: "教材精讲课",
                },
                {
                  tittle: "冲刺专题课",
                },
                {
                  tittle: "共性案例课",
                },
                {
                  tittle: "案例专项提分课",
                  desc: "（实务）",
                },
                {
                  tittle: "纯金考点课",
                },
                {
                  tittle: "真题解析课",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "历年在线真题",
                },
                {
                  tittle: "习题解析讲义",
                },
                {
                  tittle: "冲刺专题讲义",
                },
                {
                  tittle: "每科2套考前测试卷",
                },
                {
                  tittle: "强化宝典",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "专属学习档案建立",
                  img: "/lixian1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "报考协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "重修服务",
                  img: "/chongxiu1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "强化技巧课",
                  list: [
                    "1. 临考前的最后冲刺课，针对性的系统剖析；",
                    "2. 精简知识点，进行最后一遍复习，用最少的时间有效提升知识点的掌握；",
                  ],
                },
              ];
              break;
          }
          break;
        case 1154: //1342 顺序   中级安全
          switch (this.type) {
            case 1: // 启航
              this.price = [
                {
                  old: 0,
                  new: 798,
                  id: 33,
                },
                {
                  old: 0,
                  new: 898,
                },
                {
                  old: 0,
                  new: 1998,
                },
              ];
              this.list1 = [
                {
                  tittle: "备考导学课",
                },
                {
                  tittle: "基础精讲课",
                },
                {
                  tittle: "习题解析课",
                },
                {
                  tittle: "必学专题课",
                  desc: "（公开课）",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "历年在线真题",
                },
                {
                  tittle: "习题解析讲义",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "报考协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "备考导学课",
                  list: [
                    "考情分析、考试介绍、考点分布、学习方法及学习计划安排，达到入门、熟悉学习方法的目标",
                  ],
                },
                {
                  tit: "基础精讲课",
                  list: [
                    "1. 根据近三年考试分值分布情况，梳理考试必学目标内容；",
                    "2.题点结合强化记忆，高效学习，帮助考生循序渐进掌握知识点及基本考点，全面夯实基础",
                  ],
                },
                {
                  tit: "习题解析课",
                  list: [
                    "1. 精编习题，深度剖析考题考点出题规律，命中规律；",
                    "2. 了解考试题型，掌握命题规律，针对性学习",
                  ],
                },
              ];
              this.ltit = `
           <p>法规、管理、技术</p>
            <p>安全实务</p>
            <p>（道路运输、煤矿、化工</p>
            <p>建筑施工、金属冶炼</p>
            <p>金属非金属矿山、其他）</p>`;
              break;
            case 2: // 通关无忧
              this.price = [
                {
                  old: 0,
                  new: 9800,
                  id: 36,
                },
                {
                  old: 0,
                  new: 12800,
                },
                {
                  old: 0,
                  new: 25800,
                },
              ];
              this.ltit = `
           <p>法规、管理、技术</p>
            <p>安全实务</p>
            <p>(化工、建筑施工、其他)</p>`;
              this.list1 = [
                {
                  tittle: "备考导学课",
                },
                {
                  tittle: "基础精讲课",
                },
                {
                  tittle: "习题解析课",
                },
                {
                  tittle: "冲刺专题课",
                },
                {
                  tittle: "纯金考点课",
                },
                {
                  tittle: "考前密训课",
                },
                {
                  tittle: "小灶信息课",
                },
                {
                  tittle: "真题解析课",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "历年在线真题",
                },
                {
                  tittle: "习题解析讲义",
                },
                {
                  tittle: "冲刺专题讲义",
                },
                {
                  tittle: "每科2套考前测试卷",
                },
                {
                  tittle: "强化宝典",
                },
                {
                  tittle: "考前密训资料",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "专属学习档案建立",
                  img: "/lixian1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "报考协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "重修服务",
                  img: "/chongxiu1.png",
                },
              ];
              this.list4 = [
                // {
                //   tit: "案例特训课",
                //   list: [
                //     "1.根据3-5年考试规律及评卷指标，精确锁定案例分析题关键得分词句；",
                //     "2.案例题专项训练，快速提升案例分析题解题、得分能力；",
                //   ],
                // },
                {
                  tit: "考前密训课",
                  list: [
                    "独家考前密训，进一步给考试强化考试中的重中之重，增强考试中的信心",
                  ],
                },
                {
                  tit: "小灶信息课",
                  list: [
                    "详细解析核心点，帮助学员把控考试方向，熟练运用考点，突击强化考试思维，汇总高频考点，明确答题技巧",
                  ],
                },
              ];
              break;
            case 3: // VIP无忧
              this.price = [
                {
                  name: '单科',
                  old: 0,
                  new: 1280,
                  id: 34,
                },
                {
                  name: '两科联报',
                  old: 0,
                  new: 1980,
                },
                {
                  name: '全科',
                  old: 0,
                  new: 3980,
                },
              ];
              this.ltit = `
           <p>法规、管理、技术</p>
            <p>安全实务</p>
            <p>（道路运输、煤矿、化工</p>
            <p>建筑施工、金属冶炼</p>
            <p>金属非金属矿山、其他）</p>`;
              this.list1 = [
                {
                  tittle: "备考导学课",
                },
                {
                  tittle: "教材精讲课",
                },
                {
                  tittle: "习题解析课",
                },
                {
                  tittle: "冲刺串讲课",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "历年真题汇编",
                },
                {
                  tittle: "思维导图",
                },
                {
                  tittle: "习题解析讲义",
                },
                {
                  tittle: "冲刺串讲讲义",
                },
                {
                  tittle: "每科2套考前预测卷",
                },
                {
                  tittle: "考点速记手册",
                },
                {
                  tittle: "考前三页纸",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "签署协议",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "两年不过退费",
                  img: "/chongxiu1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "冲刺串讲课",
                  list: [
                    "1. 以考点形式理清科目内各种真题类型，以及解答思路；",
                    "2. 归纳总结记忆技巧，精简核心考试信息；",
                    "3. 设置实用性更强实战专题，攻克考试盲区，针对性训练；",
                    "4. 快速记忆，攻克特殊提醒阶梯思路，增强实战能力",
                  ],
                },
                {
                  tit: "习题解析课",
                  list: [
                    "以大数据收集历年学习心得，专业定制授课内容，由百位师资精心把握考试方向，筛选星级考点",
                  ],
                },
              ];
              break;
            case 4: // 领航直通
              this.price = [
                {
                  name: '单科',
                  old: 0,
                  new: 2580,
                  id: 35,
                },
                {
                  name: '两科联报',
                  old: 0,
                  new: 3980,
                },
                {
                  name: '全科',
                  old: 0,
                  new: 7980,
                },
              ];
              this.ltit = `
              <p>法规、管理、技术</p>
            <p>安全实务</p>
            <p>（道路运输、煤矿、化工</p>
            <p>建筑施工、金属冶炼</p>
            <p>金属非金属矿山、其他）</p>`;
              this.list1 = [
                {
                  tittle: "备考导学课",
                },
                {
                  tittle: "教材精讲课",
                },
                {
                  tittle: "习题解析课",
                },
                {
                  tittle: "冲刺串讲课",
                },
                {
                  tittle: "考点特训课（公共课）",
                },
                {
                  tittle: "阶段测试（公共课）",
                },
                {
                  tittle: "考前密训课",
                },
                {
                  tittle: "模考点题课",
                },
                {
                  tittle: "应试技巧课",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "历年真题汇编",
                },
                {
                  tittle: "思维导图",
                },
                {
                  tittle: "案例分析通用模板",
                },
                {
                  tittle: "习题解析讲义",
                },
                {
                  tittle: "冲刺串讲讲义",
                },
                {
                  tittle: "每科2套考前预测卷",
                },
                {
                  tittle: "考点速记手册",
                },
                {
                  tittle: "考前三页纸",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "专属学习档案建立",
                  img: "/lixian1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "签署协议",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "两年不过退费",
                  img: "/chongxiu1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "冲刺串讲课",
                  list: [
                    "1. 临考前的最后冲刺课，针对性的系统剖析；",
                    "2. 精简知识点，进行最后一遍复习，用最少的时间有效提升知识点的掌握；",
                  ],
                },
                {
                  tit: "考点特训课",
                  list: [
                    "1. 深究考题套路，高能研发试卷，多轮刷题强训，攻克疑难考点；",
                    "2. 讲练测于一体，巩固知识运，加快对知识点的把握提高；",
                  ],
                },
                {
                  tit: "应试技巧课",
                  list: [
                    "1. 以历年真题为切入点，引领熟悉解题方法；",
                    "2. 熟知历年出题思路，把控重难点分布范围，快速拔高分数；",
                  ],
                },
              ];
              break;
          }
          break;
        case 1165: //1342 顺序   执业药师
          switch (this.type) {
            case 1:
              this.price = [
                {
                  old: "",
                  new: 600,
                  id: 29,
                },
                {
                  old: "",
                  new: "",
                },
                {
                  old: "",
                  new: 1598,
                },
              ];
              this.list1 = [
                {
                  tittle: "备考导学",
                },
                {
                  tittle: "教材精讲",
                },
              ];
              this.list2 = [
                {
                  tittle: "《精讲讲义》电子版",
                },
                {
                  tittle: "《章节习题电子题库》",
                },
                {
                  tittle: "《模拟试卷》1套",
                },
                {
                  tittle: "《单元测试题库》",
                },
              ];
              this.list3 = [
                {
                  tit: "在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "移动看课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "学习追踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "报名协助",
                  img: "/xiezhu1.png",
                },

                {
                  tit: "通用学习计划",
                  img: "/zhuanshu1.png",
                },

                {
                  tit: "考试结束后答案解析",
                  img: "/tiku1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "基础精讲课",
                  list: [
                    "1. 适合零基础或基础较差、时间宽裕的成员，以新大纲、新教材为标杆逐章逐节梳理考试点；",
                    "2. 根据近三年考试分之分布情况，梳理考试必学目标内容；",
                    "3.题点结合强化记忆，高效学习，帮助考生循序渐进掌握知识点及基本考点，全面夯实基础",
                  ],
                },
              ];
              this.ltit = `
           <p>药师管理与法规</p>
            <p>中药学综合知识与技能</p>
            <p>中药学专业知识（二）</p>
            <p>中药学专业知识（一）</p>
            <p>药学综合知识与技能</p>
            <p>药学专业知识（二）</p>
            <p>药学专业知识（一）</p>`;
              break;
            case 2:
              this.price = [
                {
                  old: "",
                  new: 6000,
                  id: 32,
                },
                {
                  old: "",
                  new: "",
                },
                {
                  old: "",
                  new: 19800,
                },
              ];
              this.ltit = `
          <p>药师管理与法规</p>
            <p>中药学综合知识与技能</p>
            <p>中药学专业知识（二）</p>
            <p>中药学专业知识（一）</p>
            <p>药学综合知识与技能</p>
            <p>药学专业知识（二）</p>
            <p>药学专业知识（一）</p>`;
              this.list1 = [
                {
                  tittle: "课程导言",
                },
                {
                  tittle: "教材精讲",
                },
                {
                  tittle: "冲刺专题",
                },
                {
                  tittle: "习题解析",
                },
                {
                  tittle: "直播点睛",
                },
                {
                  tittle: "专项加密直播",
                },
              ];
              this.list2 = [
                {
                  tittle: "《精讲讲义》电子版",
                },
                {
                  tittle: "《章节习题电子题库》",
                },
                {
                  tittle: "《历年考试真题题库》",
                },
                {
                  tittle: "《模拟试卷》4套",
                },
                {
                  tittle: "考前押题试卷（2套）",
                },
                {
                  tittle: "《考点专题技巧讲义》",
                  desc: "电子版",
                },
                {
                  tittle: "《核心考点籍》电子版",
                },
                {
                  tittle: "《直播点睛讲义》",
                  desc: "电子版",
                },
                {
                  tittle: "《专项加密直播讲义》",
                  desc: "电子版",
                },
              ];
              this.list3 = [
                {
                  tit: "在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "移动看课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "学习追踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "报名协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "建立学习档案",
                  img: "/lixian1.png",
                },
                {
                  tit: "教辅指导服务",
                  img: "/1v1.png",
                },
                {
                  tit: "定制专属计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "薄弱考点分析",
                  img: "/cuoti1.png",
                },
                {
                  tit: "错题解析",
                  img: "/cuoti1.png",
                },
                {
                  tit: "免费重修服务",
                  img: "/chongxiu1.png",
                },
                {
                  tit: "考试结束后答案解析",
                  img: "/cuoti1.png",
                },
                {
                  tit: "班主任全程跟踪服务",
                  img: "/1v1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "专项加密直播课",
                  list: [
                    "1.针对平时学习时间少，基础薄弱及学习自律较差的学员进行的专项直播；",
                    "2.紧抓考点考试大纲，密集式的课程让学员短时间内把常考的重点和难点反复记忆；",
                    "3.配套有相关的资料确保顺利通过考试。",
                  ],
                },
              ];
              break;
            case 3:
              this.price = [
                {
                  old: "",
                  new: 900,
                  id: 30,
                },
                {
                  old: "",
                  new: "",
                },
                {
                  old: "",
                  new: 2980,
                },
              ];
              this.ltit = `
          <p>药师管理与法规</p>
            <p>中药学综合知识与技能</p>
            <p>中药学专业知识（二）</p>
            <p>中药学专业知识（一）</p>
            <p>药学综合知识与技能</p>
            <p>药学专业知识（二）</p>
            <p>药学专业知识（一）</p>`;
              this.list1 = [
                {
                  tittle: "备考导学",
                },
                {
                  tittle: "教材精讲",
                },
                {
                  tittle: "冲刺串讲",
                },
              ];
              this.list2 = [
                {
                  tittle: "《精讲讲义》电子版",
                },
                {
                  tittle: "《真题解析课讲义》",
                  desc: "电子版",
                },
                {
                  tittle: "《章节习题电子题库》",
                },
                {
                  tittle: "《历年考试真题题库》",
                },
                {
                  tittle: "《模拟试卷》2套",
                },
                {
                  tittle: "《仿真预测卷》2套",
                },
                {
                  tittle: "《单元测试题库》",
                },
              ];
              this.list3 = [
                {
                  tit: "在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "移动看课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "学习追踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "报名协助",
                  img: "/xiezhu1.png",
                },

                {
                  tit: "通用学习计划",
                  img: "/zhuanshu1.png",
                },

                {
                  tit: "考试结束后答题解析",
                  img: "/tiku1.png",
                },

                {
                  tit: "两年学习服务",
                  img: "/xuexi1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "冲刺专题课",
                  list: [
                    "1. 以考点形式理清科目内各种真题类型，以及解答思路；",
                    "2. 归纳总结记忆技巧，精简核心考试信息；",
                    "3. 设置实用性更强实战专题，攻克考试盲区，针对性练习；",
                    "4. 快速记忆，攻克特殊提醒阶梯思路，增强实战能力",
                  ],
                },
              ];
              break;
            case 4:
              this.price = [
                {
                  old: "",
                  new: 4000,
                  id: 31,
                },
                {
                  old: "",
                  new: "",
                },
                {
                  old: "",
                  new: 12800,
                },
              ];
              this.ltit = `
          <p>药师管理与法规</p>
            <p>中药学综合知识与技能</p>
            <p>中药学专业知识（二）</p>
            <p>中药学专业知识（一）</p>
            <p>药学综合知识与技能</p>
            <p>药学专业知识（二）</p>
            <p>药学专业知识（一）</p>`;
              this.list1 = [
                {
                  tittle: "课程导言",
                },
                {
                  tittle: "教材精讲",
                },
                {
                  tittle: "冲刺串讲",
                },
                {
                  tittle: "习题解析",
                },
                {
                  tittle: "直播点睛",
                },
              ];
              this.list2 = [
                {
                  tittle: "《精讲讲义》电子版",
                },
                {
                  tittle: "《章节习题电子题库》",
                },
                {
                  tittle: "《历年考试真题题库》",
                },
                {
                  tittle: "《模拟试卷》2套",
                },
                {
                  tittle: "《仿真预测卷》2套",
                },
                {
                  tittle: "《考点专题技巧讲义》",
                  desc: "电子版",
                },
                {
                  tittle: "《考前密押卷》2套",
                },
                {
                  tittle: "《直播点睛讲义》",
                  desc: "电子版",
                },
              ];
              this.list3 = [
                {
                  tit: "在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "移动看课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "学习追踪",
                  img: "/genzong1.png",
                },

                {
                  tit: "报名协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "建立学习档案",
                  img: "/lixian1.png",
                },
                {
                  tit: "教辅指导服务",
                  img: "/1v1.png",
                },
                {
                  tit: "定制专属计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "免费重修服务",
                  img: "/chongxiu1.png",
                },
                {
                  tit: "考试结束后答题解析",
                  img: "/tiku1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "冲刺专题",
                  list: [
                    "浓缩知识考点，提炼和讲解重难点、常考点、易错题等，是考前高效复习等必备课程",
                  ],
                },
                {
                  tit: "习题解析",
                  list: ["将历年真题、重难点题型囊括其中知识点与做题同步提升"],
                },
                {
                  tit: "核心考点集",
                  list: [
                    "1.超薄内容，高度掌握，药考主考点，考前拨分",
                    "2.考前紧抓考点，药师临考冲分必学课程",
                  ],
                },
              ];
              break;
          }
          break;
        case 1291: //1342 顺序  监理工程
          switch (this.type) {
            case 1:
              this.price = [
                {
                  old: "498",
                  new: 488,
                  id: 19,
                },
                {
                  old: "598",
                  new: 588,
                },
                {
                  old: "1598",
                  new: 1598,
                },
              ];
              this.list1 = [
                {
                  tittle: "备考导学课",
                },
                {
                  tittle: "教材精讲课",
                },
                {
                  tittle: "共性案例难点课",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "章节智能题库",
                },
                {
                  tittle: "历年在线真题",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "报考协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "备考导学课",
                  list: [
                    "考情分析、考试介绍、学科知识体系、考点分布、学习方法及学习计划安排，达到入门、熟悉学习方法的目标.",
                  ],
                },
                {
                  tit: "教材精讲班",
                  list: [
                    "根据近三年考试分值分布情况，梳理考试必学目标内容；题点结合强化记忆，高效学习，帮助考生循序渐进掌握知识点及基本考点、全面夯实基础。",
                  ],
                },
                {
                  tit: "共性案例难点课",
                  list: [
                    "根据近3-5年考试规律及评卷指标，精确锁定案例分析题关键得分词句；案例题专项训练，快速提升案例分析题解题、得分能力。案例题的专项训练，快速提升案例分析题的读题、解题、得分能力。",
                  ],
                },
              ];
              this.ltit = `
           <p>概论法规</p>
            <p>合同管理</p>
            <p>目标控制（土建）</p>
            <p>案例分析（土建）</p>`;
              break;
            //   case 2:
            //     this.price = [
            //       {
            //         old: '',
            //         new: 6000
            //       },
            //       {
            //         old: '',
            //         new: ''
            //       },
            //       {
            //         old: '',
            //         new: 19800
            //       }
            //     ]
            //     this.ltit = `
            // <p>药师管理与法规、</p>
            //   <p>中药学综合知识与技能</p>
            //   <p>中药学专业知识（二）</p>
            //   <p>中药学专业知识（一）</p>
            //   <p>药学综合知识与技能</p>
            //   <p>药学专业知识（二）</p>
            //   <p>药学专业知识（一）</p>`;
            //     this.list1 = [
            //       "课程导言",
            //       "基础精讲",
            //       "习题解析",
            //       "直播点睛",
            //       "专项加密直播",
            //     ];
            //     this.list2 = [
            //       "电子版精讲讲义",
            //       "历年在线真题题库",
            //       "章节习题电子题库",
            //       "2套模拟试卷+预测卷",
            //       "2套考前密押卷",
            //       "电子版直播点睛讲义",
            //     ];
            //     this.list3 = [
            //       {
            //         tit: "错题解析",
            //         img: "/cuoti1.png",
            //       },
            //       {
            //         tit: "报考协助",
            //         img: "/xiezhu1.png",
            //       },
            //       {
            //         tit: "学习追踪",
            //         img: "/genzong1.png",
            //       },
            //       {
            //         tit: "建立学习档案",
            //         img: "/lixian1.png",
            //       },
            //       {
            //         tit: "制定专属学习计划",
            //         img: "/zhuanshu1.png",
            //       },
            //       {
            //         tit: "班主任全程跟踪服务",
            //         img: "/1v1.png",
            //       },
            //       {
            //         tit: "移动看课",
            //         img: "/tongbu1.png",
            //       },
            //       {
            //         tit: "在线答疑",
            //         img: "/24h1.png",
            //       },
            //       {
            //         tit: "免费重修服务",
            //         img: "/chongxiu1.png",
            //       },
            //     ];
            //     this.list4 = [
            //       {
            //         tit: "专项加密直播课",
            //         list: [
            //           "1.针对平时学习时间少，基础薄弱及学习自律较差的学员进行的专项直播；",
            //           "2.紧抓考点考试大纲，密集式的课程让学员短时间内把常考的重点和难点反复记忆；",
            //           "3.配套有相关的资料确保顺利通过考试。",
            //         ],
            //       },
            //     ];
            //     break;

            //   case 3:
            //     this.price = [
            //       {
            //         old: '',
            //         new: 1280
            //       },
            //       {
            //         old: '',
            //         new: ''
            //       },
            //       {
            //         old: '',
            //         new: 3580
            //       }
            //     ]
            //     this.ltit = `
            // <p>药师管理与法规、</p>
            //   <p>中药学综合知识与技能</p>
            //   <p>中药学专业知识（二）</p>
            //   <p>中药学专业知识（一）</p>
            //   <p>药学综合知识与技能</p>
            //   <p>药学专业知识（二）</p>
            //   <p>药学专业知识（一）</p>`;
            //     this.list1 = ["课程导言", "基础精讲", "冲刺专题"];
            //     this.list2 = [
            //       "电子版精讲讲义",
            //       "2套模拟试卷",
            //       "2套仿真预测试卷",
            //       "单元测试题库",
            //       "章节习题电子题库",
            //       "历年考试真题题库",
            //     ];
            //     this.list3 = [
            //       {
            //         tit: "报考协助",
            //         img: "/xiezhu1.png",
            //       },
            //       {
            //         tit: "学习追踪",
            //         img: "/genzong1.png",
            //       },
            //       {
            //         tit: "通用学习计划",
            //         img: "/zhuanshu1.png",
            //       },
            //       {
            //         tit: "移动看课",
            //         img: "/tongbu1.png",
            //       },
            //       {
            //         tit: "考试结束后答题解析",
            //         img: "/tiku1.png",
            //       },
            //       {
            //         tit: "在线答疑",
            //         img: "/24h1.png",
            //       },
            //       {
            //         tit: "两年学习服务",
            //         img: "/xuexi1.png",
            //       },
            //     ];
            //     this.list4 = [
            //       {
            //         tit: "冲刺专题课",
            //         list: [
            //           "1. 以考点形式理清科目内各种真题类型，以及解答思路；",
            //           "2. 归纳总结记忆技巧，精简核心考试信息；",
            //           "3. 设置实用性更强实战专题，攻克考试盲区，针对性练习；",
            //           "4. 快速记忆，攻克特殊提醒阶梯思路，增强实战能力",
            //         ],
            //       },
            //     ];
            //     break;

            case 2:
              this.price = [
                {
                  old: "1598",
                  new: 1598,
                  id: 20,
                },
                {
                  old: "1998",
                  new: 1998,
                },
                {
                  old: "4998",
                  new: 4998,
                },
              ];
              this.ltit = `
             <p>概论法规</p>
            <p>合同管理</p>
            <p>目标控制（土建）</p>
            <p>案例分析（土建）</p>`;
              this.list1 = [
                {
                  tittle: "备考导学课",
                },
                {
                  tittle: "教材精讲课",
                },
                {
                  tittle: "共性案例难点课",
                },
                {
                  tittle: "核心提分课",
                },
                {
                  tittle: "考点强化课",
                },
              ];
              this.list2 = [
                {
                  tittle: "基础精讲讲义",
                },
                {
                  tittle: "章节智能题库",
                },
                {
                  tittle: "历年在线真题",
                },
                {
                  tittle: "习题解析讲义",
                },
                {
                  tittle: "冲刺专题讲义",
                },
                {
                  tittle: "考前预测资料",
                },
              ];
              this.list3 = [
                {
                  tit: "手机电脑同步听课",
                  img: "/tongbu1.png",
                },
                {
                  tit: "视频讲义离线缓存",
                  img: "/lixian1.png",
                },
                {
                  tit: "海量题库刷题提升",
                  img: "/tiku1.png",
                },
                {
                  tit: "制定专属学习计划",
                  img: "/zhuanshu1.png",
                },
                {
                  tit: "24小时在线答疑",
                  img: "/24h1.png",
                },
                {
                  tit: "班主任1V1服务",
                  img: "/1v1.png",
                },
                {
                  tit: "报名提醒",
                  img: "/baoming1.png",
                },
                {
                  tit: "报考协助",
                  img: "/xiezhu1.png",
                },
                {
                  tit: "学习跟踪",
                  img: "/genzong1.png",
                },
                {
                  tit: "免费重修一年",
                  img: "/chongxiu1.png",
                },
              ];
              this.list4 = [
                {
                  tit: "核心提分/点题课",
                  list: [
                    "以考点形式理清科目内各种真题类型，以及解答思路；归纳总结记忆技巧，精简核心考试信息；设置实用性更强实战专题，攻克考试盲区，针对性训练。快速记忆，攻克特殊题型解题思路，增强实战能力。",
                  ],
                },
                {
                  tit: "考点强化密训",
                  list: [
                    "独家考前密训，进一步给考生强化考试中的重中之重。增强考试中的信心。",
                  ],
                },
              ];
              break;
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  .l1 {
    background: url("https://www.liyouedu.cn/assets/img/qihang123.png")
      no-repeat center;
    span {
      color: #9e661e;
    }
  }
  .l2 {
    background: url("https://www.liyouedu.cn/assets/img/l2.png") no-repeat
      center;
    span {
      color: #494abb;
    }
  }
  .l3 {
    background: url("https://www.liyouedu.cn/assets/img/l3.png") no-repeat
      center;
    span {
      color: #a4672a;
    }
  }
  .l4 {
    background: url("https://www.liyouedu.cn/assets/img/l4.png") no-repeat
      center;
    span {
      color: #2968c0;
    }
  }
  .r1 {
    background: #fdfaf5;
    .t .nav div {
      color: #9e661e;
    }
    .t .navlist li {
      border-right: 1px solid #f4ead8;
    }
    .t .navlist li:nth-child(4) .p .h {
      background: #fdfaf5;
    }
    .t .navlist li p {
      color: #9e661e;
    }
    .t .navlist li .firstnavlist {
      width: 100%;
      padding-left: 80px;
      margin-bottom: 20px;
      color: #9e661e;
    }
    .t .navlist li .firstnavlist .desc {
      padding-left: 25px;
    }
    .t .navlist li:first-child .firstnavlist:first-child .desc {
      padding-left: 0;
      color: #b46f12;
      font-size: 14px;
    }
    .t .navlist li .firstnavlist .top {
      display: flex;
      align-items: center;
    }
    .t .navlist li:nth-child(4) .p {
      color: #9e661e;
      .o {
        border: 2px solid rgba(218, 164, 96, 0.6);
        span {
          color: #9e661e;
        }
      }
      .o::before {
        background: rgba(218, 164, 96, 0.6);
      }
    }
  }
  .r2 {
    background: #f0f0f7;
    .t .nav div {
      color: #494abb;
    }
    .t .navlist li {
      border-right: 1px solid #e0e0f4;
    }
    .t .navlist li:nth-child(4) .p .h {
      background: #f0f0f7;
    }
    .t .navlist li p {
      color: #494abb;
    }
    .t .navlist li .firstnavlist {
      width: 100%;
      padding-left: 80px;
      margin-bottom: 20px;
      color: #494abb;
    }
    .t .navlist li .firstnavlist .desc {
      padding-left: 25px;
    }
    .t .navlist li:first-child .firstnavlist:first-child .desc {
      padding-left: 0;
      color: #494abb;
      font-size: 14px;
    }
    .t .navlist li .firstnavlist .top {
      display: flex;
      align-items: center;
    }
    .t .navlist li:nth-child(4) .p {
      color: #494abb;
      .o {
        border: 2px solid rgba(136, 137, 231, 0.6);
        span {
          color: #494abb;
        }
      }
      .o::before {
        background: rgba(136, 137, 231, 0.6);
      }
    }
  }
  .r3 {
    background: #fdfbf8;
    .t .nav div {
      color: #a4672a;
    }
    .t .navlist li {
      border-right: 1px solid #f3e9df;
    }
    .t .navlist li:nth-child(4) .p .h {
      background: #fdfbf8;
    }
    .t .navlist li p {
      color: #a4672a;
    }
    .t .navlist li .firstnavlist {
      width: 100%;
      padding-left: 80px;
      margin-bottom: 20px;
      color: #a4672a;
    }
    .t .navlist li .firstnavlist .desc {
      padding-left: 25px;
    }
    .t .navlist li:first-child .firstnavlist:first-child .desc {
      padding-left: 0;
      color: #a4672a;
      font-size: 14px;
    }
    .t .navlist li .firstnavlist .top {
      display: flex;
      align-items: center;
    }
    .t .navlist li:nth-child(4) .p {
      color: #a4672a;
      .o {
        border: 2px solid rgba(218, 160, 103, 0.6);
        span {
          color: #a4672a;
        }
      }
      .o::before {
        background: rgba(218, 160, 103, 0.6);
      }
    }
  }
  .r4 {
    background: #f0f3f7;
    .t .nav div {
      color: #2968c0;
    }
    .t .navlist li {
      border-right: 1px solid #e1e8f3;
    }
    .t .navlist li:nth-child(4) .p .h {
      background: #f0f3f7;
    }
    .t .navlist li p {
      color: #2968c0;
    }
    .t .navlist li .firstnavlist {
      width: 100%;
      padding-left: 80px;
      margin-bottom: 20px;
      color: #2968c0;
    }
    .t .navlist li .firstnavlist .desc {
      padding-left: 25px;
    }
    .t .navlist li:first-child .firstnavlist:first-child .desc {
      padding-left: 0;
      color: #2968c0;
      font-size: 14px;
    }
    .t .navlist li .firstnavlist .top {
      display: flex;
      align-items: center;
    }
    .t .navlist li:nth-child(4) .p {
      color: #2968c0;
      .o {
        border: 2px solid rgba(99, 147, 213, 0.6);
        span {
          color: #2968c0;
        }
      }
      .o::before {
        background: rgba(99, 147, 213, 0.6);
      }
    }
  }
}
.list .l {
  background-size: 100%;
  width: 240px;
  height: 642px;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.list .l h2 {
  margin-top: 60px;
  font-size: 38px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  display: block;
}
.list .l .detail {
  padding: 9px 17px 88px 25px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: #ffffff;
  line-height: 27px;
  text-align: center;
}
.list .l span {
  display: inline-block;
  background: #ffffff;
  border-radius: 27px;
  width: 166px;
  height: 54px;
  font-weight: 500;
  cursor: pointer;
  margin: 0 33px 20px 41px;
  font-size: 18px;
  line-height: 54px;
  text-align: center;
}
.list .l .tit {
  width: 102px;
  font-size: 18px;
  font-weight: 500;
  line-height: 56px;
  color: #fff;
  text-align: center;
  height: 68px;
  background: url("https://www.liyouedu.cn/assets/img/biaoqian123x.png")
    no-repeat center;
  background-size: 100%;
  position: absolute;
  top: -12px;

  left: 12px;
}
.list .r {
  position: relative;
  width: 940px;
  border-radius: 12px;
  height: 613px;
  padding: 0 0px 29px 0px;
  .t {
    margin-top: 20px;
    .nav {
      display: flex;
      align-items: center;
      div {
        width: 25%;
        // text-align: center;
        padding-left: 30px;
        font-size: 20px;
        font-weight: 600;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
    .navlist {
      margin-top: 17px;
    }
    .navlist ul {
      display: flex;
    }
    .navlist li {
      padding-top: 23px;
      display: flex;
      font-size: 16px;
      font-weight: 400;
      min-height: 437px;
      align-items: center;
      width: 25%;
      box-sizing: border-box;
      flex-direction: column;
      p {
        display: flex;
        align-items: center;
        margin-left: 40px;
        margin-bottom: 20px;
      }
      img {
        width: 20px;
        height: 16px;
        margin-right: 5px;
      }
    }
    .navlist {
      li:last-child {
        border: none;
      }
      li p {
        width: 100%;
      }
      li:first-child p {
        // width: 140px;
      }
      li:nth-child(2) p {
        // width: 150px;
      }
      li:nth-child(3) p {
        // width: 170px;
      }
      li:nth-child(4) .p {
        width: 200px;
        line-height: 19px;
        font-size: 14px;
        margin-top: 13px;
        font-weight: 400;
        display: flex;
        .o {
          width: 42px;
          position: relative;
          display: flex;
          align-items: center;
          height: 32px;
          padding-left: 12px;
          span {
            line-height: 1;
            font-size: 22px;
            position: relative;
            font-family: DINPro;
            font-weight: 900;
          }
        }
        .o::before {
          content: "";
          display: block;
          position: absolute;
          width: 32px;
          height: 24px;
          top: 4px;
          left: 8px;
        }
        .h {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 600;
          line-height: 26px;
          height: 26px;
          position: relative;
          top: 5px;
          left: -10px;
        }
      }
    }
  }
}
.box {
  width: 1200px;
  margin: 0 auto;
}
.b {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: space-around;
  bottom: 30px;
  width: 100%;
}
.b .price {
  display: flex;
  align-items: center;
  h2 {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #333333;
  }
  .old {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
    p {
      font-size: 13px;
      font-weight: 600;
      color: #999999;
    }
    p:last-child {
      text-decoration: line-through;
    }
  }
  .but {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    cursor: pointer;
    margin-left: 20px;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 35px;
    background: linear-gradient(90deg, #f86e5c 0%, #f93e39 100%);
    .price_ {
      .blod {
        font-weight: bold;
        font-size: 18px;
        margin: 0 5px;
      }
      font-weight: bold;
      font-size: 26px;
    }
  }
}

::v-deep .bmdialog .el-dialog__title {
  font-weight: 600;
  font-family: PingFang SC;
  font-size: 22px;
  color: #3e7eff;
  display: block;
  text-align: center;
}
::v-deep .bmdialog .el-dialog__header {
  padding: 30px 0 15px;
}
::v-deep .bmdialog {
  .el-dialog {
    border-radius: 8px;
  }
  .el-dialog__body {
    padding: 0 20px 8px;
  }
  .el-dialog__body .detail {
    padding: 0 20px 40px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .el-form-item__content {
    width: 290px;
  }
  .el-select {
    width: 100%;
  }
  .el-dialog__footer {
    padding: 0 50px 30px;
  }
  .el-button {
    width: 100%;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #ffffff;
    background: #3e7eff;
    box-shadow: 0px 8px 18px 0px rgba(37, 97, 218, 0.3);
    border-radius: 26px;
  }
}
.pb12 p {
  margin-bottom: 12px !important;
}
.pb12 .firstnavlist {
  padding-left: 50px !important;
}
</style>