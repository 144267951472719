<template>
  <div class="steps">
    <div class="step" :style="num >= 1 ? 'color:#333' : 'color:#CFD5E8'">
      <img v-if="num > 1" src="../../assets/img/ok@2x.png" alt="" />
      <span v-else class="act">1</span>
      确认订单信息
    </div>
    <div class="x"></div>
    <div class="step" :style="num >= 2 ? 'color:#333' : 'color:#CFD5E8'">
      <img v-if="num > 2" src="../../assets/img/ok@2x.png" alt="" />
      <span v-else :class="num == 2 ? 'act' : ''">2</span>
      支付
    </div>
    <div class="x"></div>
    <div class="step" :style="num >= 3 ? 'color:#333' : 'color:#CFD5E8'">
      <img v-if="num > 2" src="../../assets/img/ok@2x.png" alt="" />
      <span v-else :class="num == 3 ? 'act' : ''">3</span>
      购买成功
    </div>
  </div>
</template>

<script>
export default {
  name: "Step",
  props: {
    num: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  .step {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    span {
      margin-right: 8px;
      display: block;
      text-align: center;
      line-height: 32px;
      width: 32px;
      height: 32px;
      color: #cfd5e8;
      font-size: 16px;
      border-radius: 50%;
      border: 1px solid #cfd5e8;
    }
    .act {
      border: 1px solid #4f76fb;
      color: #fff;
      background: #4f76fb;
    }
    img {
      width: 32px;
      margin-right: 8px;
    }
  }
}
.x {
  width: 80px;
  height: 1px;
  border-top: 1px dashed #cfd5e8;
  margin: 0 20px;
}
</style>